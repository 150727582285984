import {
    stateOptions,
    provinceOptions,
  } from "./constants";


export const getRegionDetails = (selectedOption) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "GBR") {
      newLabel = "County";
      options = [];
      newZipLabel = "Postcode";
    } else if (selectedOption.value === "AUS") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postcode";
    } else if (selectedOption.value === "FRA") {
      newLabel = "Region";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "DEU") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "ITA") {
      newLabel = "Region";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "JPN") {
      newLabel = "Prefecture";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "BRA") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "IND") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "CHN") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "RUS") {
      newLabel = "Federal Subject";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "KOR") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "MEX") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "IDN") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "TUR") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "SAU") {
      newLabel = "Region";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "ARG") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "ZAF") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "ITA") {
      newLabel = "Region";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "ESP") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "GBR") {
      newLabel = "County";
      options = [];
      newZipLabel = "Postcode";
    } else if (selectedOption.value === "FRA") {
      newLabel = "Region";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "BRA") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "DEU") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "IND") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "JPN") {
      newLabel = "Prefecture";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "KOR") {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "MEX") {
      newLabel = "State";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "RUS") {
      newLabel = "Federal Subject";
      options = [];
      newZipLabel = "Postal Code";
    } else if (selectedOption.value === "GBR") {
      newLabel = "County";
      options = [];
      newZipLabel = "Postcode";
    } else if (selectedOption.value === "ITA") {
      newLabel = "Region";
      options = [];
      newZipLabel = "Postal Code";
    } else {
      newLabel = "Province";
      options = [];
      newZipLabel = "Postal Code";
    }

    return {
      newLabel,
      options,
      newZipLabel,
    };
  };
