import React, { Component } from "react";

class SidePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleVolunteerClick = (e) => {
    e.preventDefault();

    window.open("https://www.bigsisters.bc.ca/become-a-mentor/", "_blank");
  };

  handleClick = (e, url) => {
    e.preventDefault();

    window.open(url, "_blank");
  };

  getTopClassnames = () => {
    const { isMobile } = this.props;

    if (isMobile) {
      return "col-lg-6 info mt-4";
    }

    return "col-lg-6 info";
  };

  getButton = (button, text, url) => {
    if (button) {
      return (
        <button
          className="btn btn-theme effect btn-md"
          onClick={(e) => this.handleClick(e, url)}
        >
          {text}
        </button>
      );
    }
  };

  render() {
    const matchedPage = this.props.matchedPage;

    console.log(matchedPage);

    if (matchedPage && matchedPage.campaign_box_1 === 1) {
      return (
        <div className={this.getTopClassnames()}>
          <form>
            <h3 style={{ color: "#2dccd3" }}>
              {matchedPage.campaign_box_1_title}
            </h3>

            {matchedPage.campaign_box_1_image && 
                <p style={{"marginTop": "25px"}}>
                <img src={matchedPage.campaign_box_1_image} alt="Campaign Image 1" />

              </p>
            }


            <div
              dangerouslySetInnerHTML={{
                __html: matchedPage.campaign_box_1_description,
              }}
            />

            {this.getButton(
              matchedPage.campaign_box_1_button,
              matchedPage.campaign_box_1_button_text,
              matchedPage.campaign_box_1_button_url
            )}
          </form>

          {matchedPage.campaign_box_2 === 1 && (
            <form className="mt-4">
              <h3 style={{ color: "#2dccd3" }}>
                {matchedPage.campaign_box_2_title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: matchedPage.campaign_box_2_description,
                }}
              />

              {this.getButton(
                matchedPage.campaign_box_2_button,
                matchedPage.campaign_box_2_button_text,
                matchedPage.campaign_box_2_button_url
              )}
            </form>
          )}

          {matchedPage.campaign_box_3 === 1 && (
            <form className="mt-4">
              <h3 style={{ color: "#2dccd3" }}>
                {matchedPage.campaign_box_3_title}
              </h3>


              <div
                dangerouslySetInnerHTML={{
                  __html: matchedPage.campaign_box_3_description,
                }}
              />

              {this.getButton(
                matchedPage.campaign_box_3_button,
                matchedPage.campaign_box_3_button_text,
                matchedPage.campaign_box_3_button_url
              )}
            </form>
          )}
        </div>
      );
    }

    return <div className="col-lg-6"></div>;
  }
}

export default SidePanel;