import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import DonationComponent from './components/DonationComponent'
import CobsCampaignComponent from './components/CobsCampaignComponent';
import BookCampaignComponent from './components/BookCampaignComponent';
import FANCampaignComponent from './components/FANCampaignComponent';
import SpringCampaignComponent from './components/SpringCampaignComponent';
import BMOCampaignComponent from './components/BMOCampaignComponent';
import TDCampaignComponent from './components/TDCampaignComponent';


function App() {
  const isSpring = window.location.href.includes("spring.");
  const UpdatedComponent = isSpring ? SpringCampaignComponent : DonationComponent;
  const location = useLocation();

  if (location.pathname.includes("virtualterminal")) {
    window.location.replace("https://virtualterminal.bigsisters.bc.ca");
    return null;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<UpdatedComponent />} />
        <Route path="/rack" element={<UpdatedComponent />} />
        <Route path="/carolandlornac" element={<UpdatedComponent />} />
        <Route path="/cobs_panorama" element={<CobsCampaignComponent />} />
        <Route path="/mjibril" element={<BookCampaignComponent />} />
        <Route path="/grapejuice2024" element={<FANCampaignComponent />} />
        <Route path="/grapejuicefan" element={<FANCampaignComponent />} />
        <Route path="/soireefan" element={<FANCampaignComponent />} />
        <Route path="/spring" element={<SpringCampaignComponent />} />
        <Route path="/tdarts2024" element={<TDCampaignComponent />} />
        <Route path="/bmomarthon2024" element={<BMOCampaignComponent />} />
        <Route path="/thankyou2024" element={<SpringCampaignComponent />} />
        <Route path="/spring2024" element={<SpringCampaignComponent />} />
        <Route path="/TYtree24" element={<SpringCampaignComponent />} />
        <Route path="/springtytree24" element={<SpringCampaignComponent />} />
        <Route path="/donation1" element={<SpringCampaignComponent />} />
        <Route path="*" element={<UpdatedComponent />} />

      </Routes>
    </div>
  );
}

export default App;
