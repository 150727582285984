import React from "react";
import CustomChoiceDropdown from "./CustomChoiceDropdown";

class NotifyContactForm extends React.Component {
  render() {
    const { formData, handleInputChange, toggleValue, handleChoiceUpdate } =
      this.props; // Destructure props

    const options = [
      { value: "mail", label: "Mail a card on my behalf" },
      { value: "email", label: "Send a message" },
    ];

    //const options = [{ value: "mail", label: "Mail a card on my behalf" }];

    const imageOptions = [
      {
        value: "TributeOption1",
        label: "Option 1",
        src: "/TributeOption1.png",
      },
      {
        value: "TributeOption2",
        label: "Option 2",
        src: "/TributeOption2.png",
      },
      {
        value: "TributeOption3",
        label: "Option 3",
        src: "/TributeOption3.png",
      },
      {
        value: "TributeOption4",
        label: "Option 4",
        src: "/TributeOption4.png",
      },
    ];

    return (
      <>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyFirstName">
              First Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyFirstName"
              placeholder="First Name"
              value={formData.notifyFirstName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyLastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyLastName"
              placeholder="Last Name"
              value={formData.notifyLastName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/*
                <CustomChoiceDropdown
          value={formData.notifyContactOptions}
          handleChoiceUpdate={handleChoiceUpdate}
          label={"Send an acknowledgement?"}
          identifier="notifyContactOptions"
          choiceOptions={options}
          defaultValue={options[0]}
          outerClass="col-md-12"
        />
        */}

        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyAddress">
              Address <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyAddress"
              placeholder="Address"
              value={formData.notifyAddress}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyCity">
              City <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyCity"
              placeholder="City"
              value={formData.notifyCity}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyProvince">
              Province <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyProvince"
              placeholder="Province/State"
              value={formData.notifyProvince}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyCountry">
              Country <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyCountry"
              placeholder="Country"
              value={formData.notifyCountry}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="notifyPostalCode">
              Postal Code <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyPostalCode"
              placeholder="Postal Code"
              value={formData.notifyPostalCode}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="notifyEmail">
              Their Email <span className="required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="notifyEmail"
              placeholder="Email"
              value={formData.notifyEmail}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="notifyPhoneNumber">
              Their Phone Number <span className="required">*</span>
            </label>

            <input
              type="text"
              className="form-control"
              id="notifyPhoneNumber"
              placeholder="Phone Number"
              value={formData.notifyPhoneNumber}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/*        

            
            <div className="col-md-12">
              <div className="form-group">

                <label>Choose an image:</label>
                <div>
                  <div style={{ display: "flex" }}>
                    {imageOptions.map((imageOption) => (
                      <label
                        key={imageOption.value}
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          type="radio"
                          name="selectedImage"
                          value={imageOption.value}
                          checked={
                            formData.notifyImageOption === imageOption.value
                          }
                          onChange={(selectedOption) =>
                            handleChoiceUpdate(
                              selectedOption.target,
                              "notifyImageOption"
                            )
                          }
                          style={{ display: "none" }}
                        />
                        <img
                          src={imageOption.src}
                          alt={imageOption.label}
                          onClick={() =>
                            document
                              .querySelector(
                                `input[value="${imageOption.value}"]`
                              )
                              .click()
                          }
                          style={{
                            width: "100px",
                            cursor: "pointer",
                            border:
                              formData.notifyImageOption === imageOption.value
                                ? "10px solid #2dccd3"
                                : "none",
                          }}
                        />
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="notifyNote">Message <span className="required">*</span></label>
            <input
              type="text"
              className="form-control"
              id="notifyNote"
              placeholder="Your message"
              value={formData.notifyNote}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default NotifyContactForm;