export const countryOptions = [
  { value: "CAN", label: "Canada" },
  { value: "USA", label: "United States" },
  { value: "AFG", label: "Afghanistan" },
  { value: "ALB", label: "Albania" },
  { value: "DZA", label: "Algeria" },
  { value: "AND", label: "Andorra" },
  { value: "AGO", label: "Angola" },
  { value: "ATG", label: "Antigua and Barbuda" },
  { value: "ARG", label: "Argentina" },
  { value: "ARM", label: "Armenia" },
  { value: "AUS", label: "Australia" },
  { value: "AUT", label: "Austria" },
  { value: "AZE", label: "Azerbaijan" },
  { value: "BHS", label: "Bahamas" },
  { value: "BHR", label: "Bahrain" },
  { value: "BGD", label: "Bangladesh" },
  { value: "BRB", label: "Barbados" },
  { value: "BLR", label: "Belarus" },
  { value: "BEL", label: "Belgium" },
  { value: "BLZ", label: "Belize" },
  { value: "BEN", label: "Benin" },
  { value: "BTN", label: "Bhutan" },
  { value: "BOL", label: "Bolivia" },
  { value: "BIH", label: "Bosnia and Herzegovina" },
  { value: "BWA", label: "Botswana" },
  { value: "BRA", label: "Brazil" },
  { value: "BRN", label: "Brunei Darussalam" },
  { value: "BGR", label: "Bulgaria" },
  { value: "BFA", label: "Burkina Faso" },
  { value: "BDI", label: "Burundi" },
  { value: "CPV", label: "Cabo Verde" },
  { value: "KHM", label: "Cambodia" },
  { value: "CMR", label: "Cameroon" },
  { value: "CAF", label: "Central African Republic" },
  { value: "TCD", label: "Chad" },
  { value: "CHL", label: "Chile" },
  { value: "CHN", label: "China" },
  { value: "COL", label: "Colombia" },
  { value: "COM", label: "Comoros" },
  { value: "COG", label: "Congo" },
  { value: "CRI", label: "Costa Rica" },
  { value: "HRV", label: "Croatia" },
  { value: "CUB", label: "Cuba" },
  { value: "CYP", label: "Cyprus" },
  { value: "CZE", label: "Czech Republic" },
  { value: "DNK", label: "Denmark" },
  { value: "DJI", label: "Djibouti" },
  { value: "DMA", label: "Dominica" },
  { value: "DOM", label: "Dominican Republic" },
  { value: "ECU", label: "Ecuador" },
  { value: "EGY", label: "Egypt" },
  { value: "SLV", label: "El Salvador" },
  { value: "GNQ", label: "Equatorial Guinea" },
  { value: "ERI", label: "Eritrea" },
  { value: "EST", label: "Estonia" },
  { value: "ETH", label: "Ethiopia" },
  { value: "FJI", label: "Fiji" },
  { value: "FIN", label: "Finland" },
  { value: "FRA", label: "France" },
  { value: "GAB", label: "Gabon" },
  { value: "GMB", label: "Gambia" },
  { value: "GEO", label: "Georgia" },
  { value: "DEU", label: "Germany" },
  { value: "GHA", label: "Ghana" },
  { value: "GRC", label: "Greece" },
  { value: "GRD", label: "Grenada" },
  { value: "GTM", label: "Guatemala" },
  { value: "GIN", label: "Guinea" },
  { value: "GNB", label: "Guinea-Bissau" },
  { value: "GUY", label: "Guyana" },
  { value: "HTI", label: "Haiti" },
  { value: "HND", label: "Honduras" },
  { value: "HUN", label: "Hungary" },
  { value: "ISL", label: "Iceland" },
  { value: "IND", label: "India" },
  { value: "IDN", label: "Indonesia" },
  { value: "IRN", label: "Iran" },
  { value: "IRQ", label: "Iraq" },
  { value: "IRL", label: "Ireland" },
  { value: "ISR", label: "Israel" },
  { value: "ITA", label: "Italy" },
  { value: "JAM", label: "Jamaica" },
  { value: "JPN", label: "Japan" },
  { value: "JOR", label: "Jordan" },
  { value: "KAZ", label: "Kazakhstan" },
  { value: "KEN", label: "Kenya" },
  { value: "KIR", label: "Kiribati" },
  { value: "PRK", label: "North Korea" },
  { value: "KOR", label: "South Korea" },
  { value: "KWT", label: "Kuwait" },
  { value: "KGZ", label: "Kyrgyzstan" },
  { value: "LAO", label: "Laos" },
  { value: "LVA", label: "Latvia" },
  { value: "LBN", label: "Lebanon" },
  { value: "LSO", label: "Lesotho" },
  { value: "LBR", label: "Liberia" },
  { value: "LBY", label: "Libya" },
  { value: "LIE", label: "Liechtenstein" },
  { value: "LTU", label: "Lithuania" },
  { value: "LUX", label: "Luxembourg" },
  { value: "MKD", label: "Macedonia" },
  { value: "MDG", label: "Madagascar" },
  { value: "MWI", label: "Malawi" },
  { value: "MYS", label: "Malaysia" },
  { value: "MDV", label: "Maldives" },
  { value: "MLI", label: "Mali" },
  { value: "MLT", label: "Malta" },
  { value: "MHL", label: "Marshall Islands" },
  { value: "MRT", label: "Mauritania" },
  { value: "MUS", label: "Mauritius" },
  { value: "MEX", label: "Mexico" },
  { value: "FSM", label: "Micronesia" },
  { value: "MDA", label: "Moldova" },
  { value: "MCO", label: "Monaco" },
  { value: "MNG", label: "Mongolia" },
  { value: "MNE", label: "Montenegro" },
  { value: "MAR", label: "Morocco" },
  { value: "MOZ", label: "Mozambique" },
  { value: "MMR", label: "Myanmar" },
  { value: "NAM", label: "Namibia" },
  { value: "NRU", label: "Nauru" },
  { value: "NPL", label: "Nepal" },
  { value: "NLD", label: "Netherlands" },
  { value: "NZL", label: "New Zealand" },
  { value: "NIC", label: "Nicaragua" },
  { value: "NER", label: "Niger" },
  { value: "NGA", label: "Nigeria" },
  { value: "NOR", label: "Norway" },
  { value: "OMN", label: "Oman" },
  { value: "PAK", label: "Pakistan" },
  { value: "PLW", label: "Palau" },
  { value: "PAN", label: "Panama" },
  { value: "PNG", label: "Papua New Guinea" },
  { value: "PRY", label: "Paraguay" },
  { value: "PER", label: "Peru" },
  { value: "PHL", label: "Philippines" },
  { value: "POL", label: "Poland" },
  { value: "PRT", label: "Portugal" },
  { value: "QAT", label: "Qatar" },
  { value: "ROU", label: "Romania" },
  { value: "RUS", label: "Russia" },
  { value: "RWA", label: "Rwanda" },
  { value: "KNA", label: "Saint Kitts and Nevis" },
  { value: "LCA", label: "Saint Lucia" },
  { value: "VCT", label: "Saint Vincent and the Grenadines" },
  { value: "WSM", label: "Samoa" },
  { value: "SMR", label: "San Marino" },
  { value: "STP", label: "Sao Tome and Principe" },
  { value: "SAU", label: "Saudi Arabia" },
  { value: "SEN", label: "Senegal" },
  { value: "SRB", label: "Serbia" },
  { value: "SYC", label: "Seychelles" },
  { value: "SLE", label: "Sierra Leone" },
  { value: "SGP", label: "Singapore" },
  { value: "SVK", label: "Slovakia" },
  { value: "SVN", label: "Slovenia" },
  { value: "SLB", label: "Solomon Islands" },
  { value: "SOM", label: "Somalia" },
  { value: "ZAF", label: "South Africa" },
  { value: "SSD", label: "South Sudan" },
  { value: "ESP", label: "Spain" },
  { value: "LKA", label: "Sri Lanka" },
  { value: "SDN", label: "Sudan" },
  { value: "SUR", label: "Suriname" },
  { value: "SWE", label: "Sweden" },
  { value: "CHE", label: "Switzerland" },
  { value: "SYR", label: "Syria" },
  { value: "TWN", label: "Taiwan" },
  { value: "TJK", label: "Tajikistan" },
  { value: "TZA", label: "Tanzania" },
  { value: "THA", label: "Thailand" },
  { value: "TLS", label: "Timor-Leste" },
  { value: "TGO", label: "Togo" },
  { value: "TON", label: "Tonga" },
  { value: "TTO", label: "Trinidad and Tobago" },
  { value: "TUN", label: "Tunisia" },
  { value: "TUR", label: "Turkey" },
  { value: "TKM", label: "Turkmenistan" },
  { value: "TUV", label: "Tuvalu" },
  { value: "UGA", label: "Uganda" },
  { value: "UKR", label: "Ukraine" },
  { value: "ARE", label: "United Arab Emirates" },
  { value: "GBR", label: "United Kingdom" },
  { value: "URY", label: "Uruguay" },
  { value: "UZB", label: "Uzbekistan" },
  { value: "VUT", label: "Vanuatu" },
  { value: "VEN", label: "Venezuela" },
  { value: "VNM", label: "Vietnam" },
  { value: "YEM", label: "Yemen" },
  { value: "ZMB", label: "Zambia" },
  { value: "ZWE", label: "Zimbabwe" }
];


export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const provinceOptions = [
  { value: "BC", label: "British Columbia" },
  { value: "AB", label: "Alberta" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "YT", label: "Yukon" },
];

export const wordingOptions = [
  { value: "Honor", label: "In Honour of" },
  { value: "Memory", label: "In Memory of" },
];

export const donationOptions = [
  { value: "WineWall", label: "Wine Wall" },
  { value: "FAN", label: "Fund a Need" },
];

export const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  selectedProvince: provinceOptions[0],
  selectedCountry: countryOptions[0],
  provinceLabel: "Province",
  provinceOptions: provinceOptions,
  zip: "",
  zipLabel: "Postal Code",
  monthly: false,
  tribute: false,
  anonymous: false,
  organization: false,
  notify: false,
  organizationName: "",
  selectedWording: wordingOptions[0],
  tributeFirstName: "",
  tributeLastName: "",
  notifyFirstName: "",
  notifyLastName: "",
  notifyAddress: "",
  notifyCountry: "",
  notifyPostalCode: "",
  notifyCity: "",
  notifyProvince: "",
  campaign: "",
  appeal: "",
  fund: "",
  package: "",
  receipt: "",
  notifyNote: "",
  notifyContactOptions: "mail",
  notifyEmail: "",
  notifyPhoneNumber: "",
  billingAddress: true,
  billingAddressLine1: "",
  billingAddressLine2: "",
  billingCity: "",
  billingSelectedProvince: provinceOptions[0],
  billingSelectedCountry: countryOptions[0],
  billingProvinceLabel: "Province",
  billingProvinceOptions: provinceOptions,
  billingZip: "",
  billingZipLabel: "Postal Code",
  payFees: false,
  behalf: false,
  ecard: false,
  behalfImageOption: "",
  behalfNote: "",
  behalfFirstName: "",
  behalfLastName: "",
  behalfEmail: "",
  behalfPhoneNumber: "",
  notifyImageOption: "",
  wineWall: false
};

export const monthlyInitialState = {
  amount: 100,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  selectedProvince: provinceOptions[0],
  selectedCountry: countryOptions[0],
  provinceLabel: "Province",
  provinceOptions: provinceOptions,
  zip: "",
  zipLabel: "Postal Code",
  monthly: true,
  tribute: false,
  anonymous: false,
  organization: false,
  notify: false,
  organizationName: "",
  selectedWording: wordingOptions[0],
  tributeFirstName: "",
  tributeLastName: "",
  notifyFirstName: "",
  notifyLastName: "",
  notifyAddress: "",
  notifyCity: "",
  notifyProvince: "",
  notifyCountry: "",
  notifyPostalCode: "",
  notifyPhoneNumber: "",
  campaign: "",
  appeal: "",
  fund: "",
  package: "",
  receipt: "",
  notifyNote: "",
  notifyContactOptions: "mail",
  notifyEmail: "",
  notifyPhoneNumber: "",
  billingAddress: true,
  billingAddressLine1: "",
  billingAddressLine2: "",
  billingCity: "",
  billingSelectedProvince: provinceOptions[0],
  billingSelectedCountry: countryOptions[0],
  billingProvinceLabel: "Province",
  billingProvinceOptions: provinceOptions,
  billingZip: "",
  billingZipLabel: "Postal Code",
  behalf: false,
  ecard: false,
  behalfImageOption: "",
  behalfNote: "",
  behalfFirstName: "",
  behalfLastName: "",
  behalfEmail: "",
  behalfPhoneNumber: "",
  notifyImageOption: "",
  wineWall: false
};

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#232323",
      fontSize: "16px",
    },
    invalid: {
      color: "#fa755a",
      fontSize: "16px",
    },
  },
};


export const DROPDOWN_STYLE = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      borderWidth: "0px",
      minHeight: "0px",
      boxShadow: "0px",

    };
  },
  menu: (baseStyles, state) => {
    return {
      ...baseStyles,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "0px",
      fontSize: "0.9rem",
      zIndex: 9999
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: state.isFocused ? "#DEEBFF" : "white",
      color: "inherit",

    };
  },
};