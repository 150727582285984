import React, { Component } from "react";
import "../assets/css/DonationComponent.css";
import "../assets/css/responsive.css";
import StripeContainer from "./StripeContainer";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Navbar"
import DirectMailNavbar from './DirectMailNavbar';
import Footer from "./Footer";
import ThankYouPage from "./ThankYouPage";
import BlueSection from "./BlueSection";
import AnimatedBackground from "./AnimatedBackground"; // Import the SVG component
import SidePanel from "./SidePanels";


class DonationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      currentURL: "",
      hasDonated: false,
      amount: 100,
      payFees: false,
      firstName: "",
      isMobile: window.innerWidth <= 990,
      pageData: [],
      matchedPage: null
    };
  }

  handleWindowResize = () => {
    // Check if screen width is less than or equal to 768 pixels (typical mobile width)
    const isMobile = window.innerWidth <= 990;

    this.setState({ isMobile: isMobile }); // Display on desktop, hide on mobile
  };

  componentDidMount() {
    console.log("Why is this mounting twice???");

    this.setState({ currentURL: window.location.href });

    window.addEventListener("resize", this.handleWindowResize);

    const currentPath = window.location.pathname;

    if (currentPath !== "/") {  // Check if not on the base URL
      this.fetchDonationPages(); 
    }
  }

  fetchDonationPages = async () => {
    this.setState({isLoading: true});

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/brenda/donation-pages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    const pageData = await response.json();

    const currentSlug = window.location.pathname.replace("/", "").toLowerCase();
    const isSoiree = currentSlug.includes("soiree2024");

    // Match the current slug with the page data

  
    const matchedPage = pageData.find((page) => {
      const slugs = page.url_slug.split(",").map(slug => slug.trim().toLowerCase());
    
      return slugs.some((slug) => currentSlug.toLowerCase().includes(slug));
    });
    

    console.log(pageData);

    let amount = 100;
    if (isSoiree) {
      amount = 500;
    } else if (matchedPage && matchedPage.default_amount) {
      amount = matchedPage.default_amount;
    }

    this.setState({pageData, matchedPage, amount, isLoading: false});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  updateIsLoading = (state) => {
    this.setState({ isLoading: state });
  };

  updateHasDonated = (state) => {
    this.setState({ hasDonated: state });
  };


  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  togglePayFees = (e) => {
    this.setState({ payFees: !this.state.payFees });
  };


  updateFirstName = (firstName) => {
    this.setState({firstName: firstName})
  }

  getNavbar = () => {
    const currentURL = window.location.href.toLowerCase();
    const isBackToSchool = currentURL.includes("backtoschool");

    if (isBackToSchool) {
      return <DirectMailNavbar />
    }

    return <Navbar />;
  }

  render() {

    return (
      <>
      <BlueSection />
        <div className="donation-area bg-gray default-padding">
          {this.getNavbar()}
          <div
            className="fixed-bg"
            id="bg-wrap"
          >
           <AnimatedBackground /> {/* Use the SVG component */}
          </div>
          {/* End Fixed BG */}
          <div className="container">
            <div className="row">

            {!this.state.isMobile && <SidePanel 
            isMobile={this.state.isMobile} 
            matchedPage={this.state.matchedPage} />}
              
              <div className="col-lg-6 info text-light donation-form">
                {this.state.hasDonated ? <ThankYouPage amount={this.state.amount} 
                updateHasDonated={this.updateHasDonated} 
                firstName={this.state.firstName}  /> : (
                  <StripeContainer 
                  updateIsLoading={this.updateIsLoading} 
                  updateHasDonated={this.updateHasDonated} 
                  amount={this.state.amount}  
                  updateAmount={this.updateAmount}
                  payFees={this.state.payFees}
                  togglePayFees={this.togglePayFees}
                  updateFirstName={this.updateFirstName}
                  matchedPage={this.state.matchedPage}
                  />
                )}
              </div>

              {this.state.isMobile && <SidePanel 
              isMobile={this.state.isMobile}
              matchedPage={this.state.matchedPage} />}

            </div>
          </div>
        </div>
        {this.state.isLoading && <Spinner />}
        <ToastContainer />
        <Footer />
      </>
    );
  }
}

export default DonationComponent;
