import React from "react";
import "../assets/css/DonationOptions.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

class DonationOptions extends React.Component {
  getAmounts = () => {
    let amounts = [];
    const {matchedPage} = this.props;

    console.log(matchedPage);

    if (this.props.isSoiree) {
      if (this.props.formData.wineWall) {
        amounts = [30, 100, 300, 450, 650];
      } else {
        amounts = [250, 500, 1000, 2500, 5000];
      }
    } else if (matchedPage && matchedPage.donation_options) {
      
      const donation_options = matchedPage.donation_options.split(",").map(option => Number(option.trim()));
      amounts = donation_options;
    } else {
      amounts = [25, 50, 100, 250, 500];
    }

    if (!matchedPage || !matchedPage.donation_options) {
      // Sort amounts in descending order
      amounts.sort((a, b) => b - a);
    }


    // Add the empty string at the end
    amounts.push("");

    return amounts;
  };

  renderButtons = () => {
    let amounts = this.getAmounts();

    return (
      <>
        {amounts.map((amount, index) => (
          <li key={index}>
            <button
              type="button"
              className={`form-btn ${
                this.props.amount === amount ||
                (amount === "" && !amounts.includes(this.props.amount))
                  ? "active"
                  : "inactive"
              }`}
              onClick={() => this.props.handleAmountClick(amount)}
            >
              <span className="button-content">
                <FontAwesomeIcon
                  icon={
                    this.props.amount === amount ||
                    (amount === "" && !amounts.includes(this.props.amount))
                      ? faSquareCheck
                      : faSquare
                  }
                  className="checkbox-icon icon-lg"
                />
                {amount === "" ? <>&nbsp;Other</> : <>&nbsp;${amount}</>}
              </span>
            </button>
          </li>
        ))}
      </>
    );
  };

  render() {
    return (
      <div className="causes-area causes-single-items">
        <div className="col-md-12 item">
          <ul className="group">{this.renderButtons()}</ul>
        </div>
      </div>
    );
  }
}

export default DonationOptions;